import {
  Ellips,
  Eye,
  Men,
  Telephone,
} from "../../images/SingleCase/VideoConfTool"

const dataForVideoConfTool = {
  type: "project",
  smart: true,
  classComponent: "VideoconfTool",
  nextLink: "cases/fcteam/",
  prevLink: "cases/chernomorsk/",
  data: {
    title:
      "Face tracking <span>and</span> recognition <br><span>for video-conferencing</span>",
    subtitle:
      "The mobile application is built on React Native and works on both iOS and Android. The purpose of the application is to add fun elements to the communication between the users as well as to introduce face detection algorithms for further enhancement of the user experience.",
    overview: {
      title: "Project overview",

      about: {
        title: "About client",
        content: "UAE company engaged in Influencer business, 1-10 employees",
      },
      challenge: {
        title: "",
        content: {
          textOne: 'For this WebRTC-based video-conferencing mobile application we developed a built-in face recognition tool.',
          textTwo: 'The Swedish-based customer wanted the tool to include such features as:',
          list: [
            {
              id: '1',
              text: 'Adding face filters, masks and animated reactions in real time.'
            },
            {
              id: '2',
              text: 'After locating the voice, facial detection algorithms are used to determine if the person is speaking to other people in the room or to meeting participants joining by video.'
            }
          ]
        }
      },
      specific: [
        {
          type: "Platform",
          content: ["Web"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: [
            "WebRTC (Kurento)",
            "Websockets",
            "Xmpp",
            "SIP",
            "HTTP",
            "HTTPS",
            "Xmlrpc",
            "JavaScript",
            "NodeJS",
            "React Native",
            "JQuery",
            "MySQL",
            "MS SQL",
            "SOAP",
            "Rest API",
            "AWS/GCP",
          ],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "DevOps",
              count: 1,
            },
            {
              type: "Backend/AWS Developer",
              count: 1,
            },
            {
              type: "React Developer",
              count: 1,
            },
            {
              type: "QA Engineer",
              count: 1,
            },
            {
              type: "Designer",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "24 months",
              count: "",
            },
          ],
        },
        {
          type: "Client",
          content: ["Startup"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "We developed Mobile and Web applications to allow video and audio calls via WebRTC, screen capture, screen sharing as well as adding face filters, masks and animated reactions. Another feature we introduced is a complex facial detection mechanism to determine if the person is speaking to colleagues sitting nearby or to the entire audience joining the chat from all over the world. The Web-application supports mobile, tablets and desktop devices with the latest browser versions.",
      ],
    },

    delivered: {
      title: "",
      list: [],
    },

    images: {
      men: Men,
      telephone: Telephone,
      eyeSim: Eye,
      dot: Ellips,
    },
  },
  imageSrc: [],
  relatedProjects: [
    {
      link: "/cases/service-doc",
      id: "service-doc",
      title: "Service Doc",
    },
    {
      link: "/cases/crm-sap-integration",
      id: "crmSap",
      title: "CRM/SAP Integration",
    },
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
  ],
  link: "/cases/videoconftool",
}

export default dataForVideoConfTool
