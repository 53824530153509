import React from "react"

import OverviewComponent from "../OverviewComponent"

export default function VideoConfTool({ data }) {
  let i = 0
  return [
    <section className="first-section white-bg bgImage" key={i++}>
      <div className="bgBlock  videoconftool">
        <div className="content" style={{ height: "100%" }}>
          <div className="titleBlock">
            <h1 dangerouslySetInnerHTML={{ __html: data.title }} />
            <img
              className="image-in"
              src={data.images.telephone}
              alt="telephone picture"
            />
            <span>{data.subtitle}</span>
          </div>

          <img
            className="image-out"
            src={data.images.telephone}
            alt="telephone picture"
          />
        </div>

        <img className="menPic" src={data.images.men} alt="men" />
      </div>
    </section>,

    <OverviewComponent
      {...data}
      classComponent="videoconftool white-bg"
      key={i++}
    />,

    <section className="pre-solution videoconftool white-bg" key={i++}>
      <div className="pre-solution-bg" id="videoconftool-eye">
        <img src={data.images.eyeSim} alt="videoconftool picture" />
      </div>
    </section>,

    <section className="white-bg solution paddings videoconftool" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2>{data.solution.title}</h2>
            {data.solution.textFirst.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </section>,

    <section
      className="white-bg post-solution videoconftool last"
      style={{ display: "none" }}
      key={i++}
    ></section>,

    <section className="white-bg value paddings last" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2>{data.delivered.title}</h2>
            {data.delivered.list.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </section>,
  ]
}
